import React from "react";

interface EditableFieldProps {
	small?: boolean;
	value: string | number;
	id: string;
	name: string;
	inEditId: string | number;
	label?: string;
	isSocialLink?: boolean;
	className?: string;
	setInEditId: React.Dispatch<React.SetStateAction<string | number>>;
	handleChange: (e: any, id?: any) => void;
	handleBlur?: (e: any, isSocialLink?: boolean) => void;
}

const EditableField = ({small = false, value, id, name, inEditId, label, isSocialLink, className, setInEditId, handleChange, handleBlur}: EditableFieldProps) => {
	const handleClick = () => {
		setInEditId(id);
	}
	return(
		<div className="editable-field">
			{label && <label htmlFor="">{label}</label>}
			{inEditId === id ? (
				<input
					autoFocus
					type={id === "email" ? "email" : "text"}
					name={name || id}
					value={value}
					disabled={inEditId !== id}
					className={`edit-input ${small ? "small": ""}`}
					onChange={(e) => {
						if(isSocialLink){
							handleChange(e, id)
							return;
						}
						handleChange(e)
					}}
					onBlur={(e) => {
						setInEditId("");
						handleBlur && handleBlur(e, isSocialLink);
					}}
				/>
			) : (
				<p 
					onClick={handleClick} 
					className={`${className} name`}
				>
					{value || `Enter ${name || id} here`}
				</p> 
			)}

		</div>
	)
}

export default EditableField;