import React, { useRef, useState } from 'react'
import { Input, Button, Advert } from "components"
import Modal from 'components/Modal';
import { toast } from 'react-toastify';
import { IDLE, SUCCESS } from '../../constants';
import ImageUploading from 'react-images-uploading';


interface Props {
    
}

const CreateAds = (props: Props) => {
    const [status, setStatus] = useState(IDLE);
    const [ fields, setFields] = useState({
         img: "",
         cta: "Join Now",
         title: "Where Work Meets Play",
         link: "https://google.com",
         description: "Best one headline advertising in the world goes here",
         duration: "7",
     });

     const inputRef: any = useRef(null);

     const handleChange = (e: any) => {
        const {value, name} = e.target;

        setFields((prev) => ({...prev, [name]: value}));
         
     }


     const handleAdCreation = ({closeModal}: any) => {
         toast.success("Ad published successfully.");


         setStatus(SUCCESS);
         closeModal();
     }

     const handleFocus = () => {
            inputRef.current.focus()
     }


     // Handle Image Upload
     const [images, setImages] = React.useState([]);
     const maxNumber = 69;
   
     const onChange = (imageList: any) => {
       setImages(imageList);
       setFields((prev) => ({
           ...prev,
           img: imageList[0]['data_url']
       }))
     };

     const formIsValidate = () => {
         const formValues = Object.values(fields)
         const validFields = formValues.filter((value) => Boolean(value));
         if(validFields.length < formValues.length){
             toast.error("Fields cannot be empty");
             return false;
         }
         return true;
     }
    return (
        <>
         <div className="ads">
            <h2>Create Advertisement</h2>
            <form onSubmit={(e) => e.preventDefault()}>
            <div className="fields">
                <div className="image-cont">
                    <p>Image</p>
                    <ImageUploading
                        value={images}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                    >
                        {({
                        onImageUpload,
                        dragProps, 
                        }) => (
                            <div {...dragProps} onClick={onImageUpload} className="upload__image-wrapper details">
                                <img src="/icons/dummy-img.svg" alt="icon" />
                                <span>Add Image</span>
                            </div>
                        )}
                    </ImageUploading>
                </div>
                <Input
					type="text"
					label="Call to Action"
					placeholder="Join Now"
					name="cta"
                    value={fields.cta}
                    onChange={(e: any) => handleChange(e)}
					className="ads-form"
                    required
				/>

                <Input
					type="text"
					label="Ad Title (50 characters max)"
					placeholder="Where Work Meet Play"
                    maxLength={30}
					name="title"
                    value={fields.title}
                    onChange={(e: any) => handleChange(e)}
					className="ads-form"
                    required
				/>

                <Input
					type="text"
					label="Ad Link"
					placeholder="https//:google.com"
					name="link"
                    value={fields.link}
                    onChange={(e: any) => handleChange(e)}
					className="ads-form"
                    required
				/>

                <div className="textarea-box">
                    <label htmlFor="message" className="">
                        Description (100 characters max)
                    </label>
                    <textarea
                        name="description"
                        id="message"
                        cols={10}
                        rows={5}
                        value={fields.description}
                        onChange={(e: any) => handleChange(e)}
                        className=""
                        maxLength={100}
                        required
                    />
                </div>

                <div className="input-wrapper" style={{position: 'relative'}}>
                    <Input
                        type="text"
                        ref={inputRef}
                        label="Duration"
                        placeholder="7"
                        name="duration" 
                        maxLength={3}
                        value={fields.duration}
                        onChange={(e: any) => handleChange(e)}
                        className="ads-form"
                        required
                    />
                    <span onClick={handleFocus}>days</span>
                </div>
            </div>

            <Advert fields={fields}/>

            <Modal
                onClick={handleAdCreation}
                subtitle="You're about to create an ad. Confirm if you want to publish it."
                status={status}
            >
                    {({handleClick}: any) => (
                        <Button 
                            type="submit" 
                            onClick={() => formIsValidate() && handleClick({modalType: "Publish Ad"})} 
                            text="Publish Ad" 
                            className="create-btn" 
                        />
                    )}
            </Modal>
            </form>
        </div>
    </>
    )
}



export default CreateAds;