import { useState } from "react"
import { Button, Input } from "components"
import { _validateEmail } from "utils"
import { toast } from "react-toastify"
import { BASE_URL, IDLE, LOADING } from "../../constants";

export const AdminRecoverPassword = () => {

	const [email, setEmail] = useState("");
	const [err, setErr] = useState("");
	const [status, setStatus] = useState(IDLE)



	const handleSubmit = async (e: any) => {
		e.preventDefault();

		const isEmail = _validateEmail(email);

		if (email.length === 0 || !isEmail){
			setErr("Enter a valid email.");
			setTimeout(() => {
				setErr("");
			}, 1000)
			return;
		}

		try {
			setStatus(LOADING);
			const response = await fetch(`${BASE_URL}/password/forgot`, {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({email})
			})
			if(response.status === 204){
				toast.success("Check your mail for further instruction.");
				setEmail("");
				setStatus(IDLE);
			}else{
				setStatus(IDLE);
				toast.warning("Something went wrong.");				
			}
		} catch (error: any) {
			toast.warning(error.message);
		}
			
	}

	return (
		<form onSubmit={(e) => handleSubmit(e)}>
			<h3 id="title">Recover Password</h3>
			<Input
				type="email"
				value={email}
				placeholder="Email"
				onChange={(e: any) => setEmail(e.target.value)}
			/>
			<div className="form-errors">
				{err && <p id="orange-error">{err}</p>}
			</div>

			<div className="btn-row-box">
				<Button
					type="submit"
					text="Recover"
					className="small-blue-btn"
					loading={status === LOADING}
				/>
			</div>
		</form>
	)
}
