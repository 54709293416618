const ScreenSizeWarningMessage = () => {

	return (
		<div style={{height: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
			<div style={{ maxWidth: '400px', textAlign: 'center'}}>
				<p style={{ fontSize: '1.2rem', lineHeight: 1.5, fontWeight: 600 }}>You need a device with a bigger screen to log in like a PC</p>
			</div>
		</div>
	)
}


export default ScreenSizeWarningMessage;