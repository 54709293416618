import { useEffect } from "react";
import { useTable, usePagination } from "react-table";
import { Loader } from "components";

interface tableTypes {
  columns: any;
  data: any;
  className?: string;
  loading: boolean;
  totalPages: number;
  onRowClick?: (id: string) => void;
  fetchData?: ({ pageIndex }: { pageIndex: number }) => void;
}

export const Table = ({ columns, data, onRowClick, loading, fetchData, totalPages }: tableTypes) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 1, pageSize: 10 },
      manualPagination: true,
      pageCount: totalPages + 1,
    },
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = tableInstance;

  useEffect(() => {
    if (pageIndex !== 1) {
      fetchData && fetchData({ pageIndex });
    }
  }, [pageIndex, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <table className="admin-table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any) => {
            prepareRow(row);
            return (
              <tr onClick={() => onRowClick && onRowClick(row.original.id)} {...row.getRowProps()}>
                {row.cells.map((cell: any) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
          <tr>
            {loading && (
              <td colSpan={10000}>
                <Loader primary />
              </td>
            )}
          </tr>
        </tbody>
      </table>

      <div className="pagination">
        <button className="box" id="prev" disabled={pageIndex <= 1 || !canPreviousPage} onClick={() => !loading && previousPage()}>
          <img src="/icons/arrow-left.svg" alt="arrow" id="arrow" />
          <span>Prev</span>
        </button>
        <p id="text">
          Showing {pageIndex} to {pageCount - 1}
        </p>
        <button className="box" id="next" disabled={pageIndex >= pageCount || !canNextPage} onClick={() => !loading && nextPage()}>
          <span>Next</span>
          <img src="/icons/arrow-right.svg" alt="arrow" id="arrow" />
        </button>
      </div>
    </>
  );
};
