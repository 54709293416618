import React, { useState } from "react"
import { Button, Input } from "components"
import { LOADING, IDLE, SUCCESS, ERROR } from "../../constants"
import { fetchClient } from "utils/api"
import { Link } from "react-router-dom";


const INIT_VALUES = {
	email: "",
	password: ""
}
export const AdminLoginForm = ({ setAuth }: any) => {

	const [status, setStatus] = useState(IDLE);
	const [errors, setErrors] = useState(INIT_VALUES)
	const [formDetails, setFormDetails] = useState({
		email: "",
		password: ""
	})

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		
		setErrors(INIT_VALUES);

		if(!formDetails.email){
			setErrors((prev) => ({...prev, email: "Email field cannot be blank."}));
			return;
		}
		if(!formDetails.password){
			setErrors((prev) => ({...prev, password: "Password field cannot be blank."}));
			return;
		}
		setStatus(LOADING);
		const data: any = await fetchClient({
			method: "POST", 
			path: "/sessions", 
			token: "", 
			data: formDetails
		});
		
		if(data.status === "error"){
			const errorMsg = data.message === "NetworkError when attempting to fetch resource." ? "Check your connection" : "Invalid login details."
			setErrors((prev) => ({...prev, password: data.message}));
			setErrors((prev) => ({...prev, password: errorMsg}));
			setStatus(ERROR);
		}else{
			setAuth({data: data.user, tkn: data.token});
			setStatus(SUCCESS);
		}			
	}
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {

		const {name, value} = e.target;
		if(name === "email"){
			setFormDetails((prev: any) => ({...prev, [name]: value.toLowerCase()}))
		}else{
			setFormDetails((prev: any) => ({...prev, [name]: value}))
		}

		
	}

	return (
			<form onSubmit={(e) => handleSubmit(e)}>
				<h3 id="title">Admin Login</h3>
				<Input
					type="email"
					name="email"
					placeholder="Email"
					value={formDetails.email}
					autoComplete="off"
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
					required
				/>
				<div className="form-errors">
					<p id="orange-error">{errors.email}</p>
				</div>
				
				<Input
					type="password"
					name="password"
					placeholder="Password"
					value={formDetails.password}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
					autoComplete="off"
					required
				/>
				{errors.password && (
					<div className="form-errors" style={{display: "flex", alignItems: "center"}}>
						<p id="orange-error">{errors.password}</p>
						<Link className="redirect-link" to="/recover-password">
							Forgot your password?
						</Link>
					</div>
				)}
				

				

				<div className="btn-row-box">
					<Button
						type="submit"
						text="Login"
						className="small-blue-btn"
						loading={status === LOADING}
					/>
				</div>
			</form>
	)
}
