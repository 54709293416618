import { ReactElement, useState, useEffect, createContext, useContext } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Sidebar, Header, FullPageLoader } from "components"

import { AuthContext } from '../../App';
import { LOADING, SUCCESS } from "../../constants";
import { _isAnEmpytyObject } from "utils";


interface Props {
	children?: ReactElement;
}


export const ProfileContext = createContext({});

const forbiddenPaths = ["/manage-admins", "/create-admin"]
const AdminLayout = ({ children }: Props) => {
	const history = useHistory()
	const { pathname } = useLocation();
	const [status, setStatus] = useState(LOADING);

	// Redirect back to login when no user is signed in.
	const {setUser, setToken, setIsSignedIn, user, token, isSignedIn}:any = useContext(AuthContext);
	
	if(!isSignedIn){
		history.push("/");
	}

	useEffect(() => {
		const userObj = JSON.parse(sessionStorage.getItem("user") || '{}');
		const token = sessionStorage.getItem("token");

		if(!_isAnEmpytyObject(userObj) && token){
			setUser(userObj);
			setToken(token);
			setIsSignedIn(true);
			
			setStatus(SUCCESS);
			history.push(pathname);
		}
	}, [history, pathname, setIsSignedIn, setToken, setUser]);

	if(status === LOADING){
		return (
			<FullPageLoader />
		)
	}


	// Protect routes from ordinary admins
	const userRole = user.roles[0].slug;
	
	if (userRole !== 'super-admin' && forbiddenPaths.includes(pathname)){
		history.push("/new-request");
		return <></>;
	}
	
	return (
		<div className="admin-layout">
			<div className="sidebar-cont">
				<Sidebar 
					name={user.first_name}
					title={user.roles[0].slug.split("-").join(" ")}
				/>
			</div>
			<div className="body">
				<div className="header-cont">
					<Header token={token}/>
				</div>
				<div className="content-cont">
					{children}
				</div>
			</div>
		</div>
	)
}


export default AdminLayout;