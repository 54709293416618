import React, { FC } from "react"

interface Props {
	type?: string
	loading?: boolean
	disabled?: boolean
	placeholder?: string
	name?: string
	className?: string
	id?: string
	required?: boolean
	autoComplete?: string
	value?: string
	double?: boolean
	label?: string
	icon?: string
	defaultValue?: any
	errorMessage?: any
	errorDetail?: any
	onChange?: any
	maxLength?: number
	rest?: any
}

export const Input = React.forwardRef(({
	type,
	value,
	loading,
	name,
	label,
	disabled,
	placeholder,
	className,
	id,
	autoComplete,
	onChange,
	...rest
}: Props, ref: any) => {
	return (
		<div className={`form-input ${label && "with-label"} ${className && className}`} id={id}>
			{label && <div id="label">{label}</div>}
			<div className={`input-box ${label && "input-with-label"}`}>
				<input
					{...rest}
					type={type}
					name={name}
					autoComplete={autoComplete}
					placeholder={placeholder}
					value={value}
					disabled={disabled}
					id={id}
					onChange={onChange}
					ref={ref}
				/>
			</div>
		</div>
	)
});

export const DoubleInput: FC<Props> = ({
	children,
	errorMessage,
	errorDetail,
	type,
	value,
	loading,
	required,
	disabled,
	placeholder,
	className,
	autoComplete,
	...rest
}) => {
	return (
		<div className="double">
			<div className="input-box">{children}</div>
			{(errorMessage || errorDetail) && (
				<div className="form-errors">
					<p id="orange-error">{errorMessage}</p>
					<p id="blue-error">{errorDetail}</p>
				</div>
			)}
		</div>
	)
}
