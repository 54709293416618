import { BASE_URL } from "../constants";

interface Props {
  token: string;
  data?: any;
}
const requestOptions = ({ token, data }: Props) => ({
  GET: {
    headers: { Authorization: "Bearer " + token },
  },
  POST: {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  },
  PATCH: {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  },
});

interface fetchProps extends Props {
  path: string;
  method?: string;
}

export const fetchClient = async ({ path, token, data, method = "GET" }: fetchProps) => {
  const optionsObj: any = requestOptions({ token, data });
  const options = optionsObj[method.toUpperCase()];
  console.log(BASE_URL);
  try {
    const response = await fetch(`${BASE_URL}${path}`, {
      ...options,
    });

    const result = await response.json();

    return result;
  } catch (error: any) {
    return {
      status: "error",
      message: error.message,
    };
  }
};
