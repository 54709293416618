import React, { useState } from 'react'


interface ImageProps {
    img: string
}
const ImagePopUp = ({img}: ImageProps) => {
    const [isOpen, setIsOpen] = useState(false);


    const handleClick = (e: any) => {        
        const classNames = Array.from(e.target.classList);

        if(!classNames.includes("popup-img")){
            setIsOpen((prev) => !prev);
        }       
    }

    return (
        <>
        <div style={{backgroundImage: `url(${img || "/icons/selfie-photo.png"})`}}  className="big-img" onClick={(e) => handleClick(e)}>

        </div>
        {isOpen ? (
            <div onClick={(e) => handleClick(e)} id="img-popup">
                <div className="img-cont">
                    {/* <button onClick={(e) => handleClick(e)} className="close">X</button> */}
                    <img className="popup-img" src={img || "/icons/selfie-photo.png"} alt="User" />
                </div>
            </div>
        ) : (
            null
        )}
        </>
    )
}

export default ImagePopUp
