export const Footer = () => {
	return (
		<footer className="footer">
			<ul className="socials">
				<li>
					<a href="https://www.verifiedcreators.io/why" target="_blank" rel="noreferrer">Why</a>
				</li>
				<li>
					<a href="mailto:support@verifiedcreators.io" target="_blank" rel="noreferrer">Contact</a>
				</li>
				<li>
					<a href="https://bitclout.com/u/VerifiedCreators" target="_blank" rel="noreferrer">Bitclout</a>
				</li>
				<li>
					<a href="https://twitter.com/V_Creators_" target="_blank" rel="noreferrer">Twitter</a>
				</li>
			</ul>
			<p id="copyright">&copy; {new Date().getFullYear()} VerifiedCreators</p>
		</footer>
	);
};
