import { useEffect, useState } from "react"
import { Input, Button } from "components"

import Modal from './../../components/Modal';
import { toast } from "react-toastify";
import { IDLE, LOADING, SUCCESS, ERROR } from "../../constants";
import { fetchClient } from "utils/api";

interface Props {
	token: string
}

const INITIAL_VALUES = {
	firstName: "",
	lastName: "",
	email: "",
	password: "",
	repeatPassword: ""
}
const CreateAdmin = ({token}: Props) => {
	const [validated, setValidated] = useState(false);
	const [status, setStatus] = useState<string>(IDLE)
	const [error, setError] = useState("");
	const [fields, setFields] = useState(INITIAL_VALUES);

	const handleChange = (e: any) => {
		
		const {value, name} = e.target;

		if(name === "password"  && value !== fields.repeatPassword){
			setError("Password does not match");
		}else if(name === "repeatPassword"  && value !== fields.password){
			setError("Password does not match");
		}else if(value === fields.password){
			setError("");
		}

		if(name === "email"){
			setFields((prev)=> ({...prev, [name]: value.toLowerCase()}));
		}else{
			setFields((prev)=> ({...prev, [name]: value}))
		}
		
	}

	const handleSubmit = (e: any) => {
		e.preventDefault();
	}

	const handleCreateAdmin = async ({closeModal}: any) => {
		setStatus(LOADING);
		const data:any = await fetchClient({
			method: "POST",
			token,
			data: {
				first_name: fields.firstName, 
				last_name: fields.lastName, 
				email: fields.email, 
				password: fields.password, 
				password_confirmation: fields.repeatPassword, 
				role_slugs: ["admin"]
			},
			path: "/users"
		})
		
		if(data.status === "error"){
			toast.error(data.message);
			setStatus(ERROR);
			return;
		}
		toast.success("Admin Created Successfully.");
		closeModal();
		setStatus(SUCCESS);
		setFields(INITIAL_VALUES);
	}

	const validationCheck = () => {

		const fieldsValue = Object.values(fields);

		const condition = fieldsValue.filter((field: any) => Boolean(field) ).length === fieldsValue.length;

		if(condition && !error) {
			setValidated(true);
		}else{
			setValidated(false);
		}
	}


	useEffect(() => {
		validationCheck();

		//eslint-disable-next-line
	}, [fields])
	return (
		<>
		<div className="create">
			<h2>Create New Admin</h2>
			<form className="form" onSubmit={(e) => handleSubmit(e)}>
				<div className="fields">
					<Input
						type="text"
						label="First Name"
						placeholder="John"
						name="firstName"
						value={fields.firstName}
						onChange={(e: any) => handleChange(e)}
						className="admin-form"
						required
					/>

					<Input
						type="text"
						label="Last Name"
						placeholder="John"
						name="lastName"
						value={fields.lastName}
						onChange={(e: any) => handleChange(e)}
						className="admin-form"
						required
					/>

					<Input
						type="email"
						label="Email"
						placeholder="John@gmail.com"
						name="email"
						value={fields.email}
						onChange={(e: any) => handleChange(e)}
						className="admin-form"
						autoComplete="off"
						required
					/>
				</div>
				<div className="fields">
					<Input
						type="password"
						label="Password"
						placeholder=""
						name="password"
						value={fields.password}
						onChange={(e: any) => handleChange(e)}
						className="admin-form"
						autoComplete="off"
						required
					/>

					<Input
						type="password"
						label="Repeat Password"
						placeholder=""
						name="repeatPassword"
						value={fields.repeatPassword}
						onChange={(e: any) => handleChange(e)}
						className="admin-form"
						required
					/>
					<div style={{width: "30%"}}>

					</div>						
				</div>
					
				<div className="form-errors">
					<p id="orange-error">{error}</p>
				</div>
				<Modal
					onClick={handleCreateAdmin}
					subtitle="You're about to create an admin. Confirm if you want to proceed."
					status={status}
				>
						{({handleClick}: any) => (
							<Button 
								type="submit"
								onClick={() => validated && handleClick({modalType: "Add Admin"})} 
								text="Create Admin" 
								loading={status === LOADING}
								className="create-btn" 
							/>
						)}
				</Modal>
			</form>
		</div>
	</>	
	)
}


export default CreateAdmin;