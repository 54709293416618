import { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom"

import { 
	BellIcon,
	UserCheckedIcon,
	UserRevokedIcon,
	UserPlusIcon,
	CheckedIcon,
	UsersIcon,
	DollarIcon,
	UserMinusIcon,
	LogoutIcon, 
	CookieIcon
} from "./Icons/Icons";
import { AuthContext } from '../App';

interface Props {
	title: string;
	name: string
}

export const Sidebar = ({title, name}: Props) => {
	const history = useHistory();

	const { handleLogout, user }: any = useContext(AuthContext);

	const userRole = user?.roles[0]?.slug;

	return (
		<div className="sidebar">
			<div className="top">
				<img src="/icons/logo.svg" alt="logo" id="logo" onClick={() => history.push("/new-request") } />
				<div className="top-right">
					<p id="title">{title}</p>
					<p id="name">{name}</p>
				</div>
			</div>

			
			<div className="down">
				<div className="list-1">
					<SidebarMenu
						menu="New Requests"
						route="new-request"
						Icon={BellIcon}
					/>
					<SidebarMenu
						menu="Verified Users"
						route="verified-users"
						Icon={UserCheckedIcon}
					/>
					<SidebarMenu
						menu="Pending Verifications"
						route="pending-verifications"
						Icon={CheckedIcon}
					/>
					<SidebarMenu
						menu="Revoked Verifications"
						route="revoked-verifications"
						Icon={UserRevokedIcon}
					/>
					<SidebarMenu
						menu="Rejected Users"
						route="rejected-verifications"
						Icon={UserMinusIcon}
					/>
				</div>

				<div className="list-2">
					{userRole === "super-admin" && (
						<>
							<SidebarMenu
								menu="Create Admin"
								route="create-admin"
								Icon={UserPlusIcon}
							/>
							<SidebarMenu
								menu="Manage Admin"
								route="manage-admins"
								Icon={UsersIcon}
							/>
						</>
					)}

					<SidebarMenu
						menu="Create Ads"
						route="create-ads"
						Icon={DollarIcon}
					/>
				</div>

				<div className="list-3">
					<SidebarMenu
						menu="Create NFT"
						route="create-nft"
						Icon={CookieIcon}
					/>
				</div>

				<div className="list-4">
					<div className="list" onClick={() => handleLogout()}>
						<div className="side-icon">
							<LogoutIcon color="#010B1A" />
						</div>
						<p className="menu-text logout">LogOut</p>
					</div>
				</div>
			</div>
		</div>
	)
}

const SidebarMenu = ({ menu, route, Icon, activeIcon }: any) => {
	const { pathname } = useLocation()
	const history = useHistory()

	const isActiveMenu = pathname === `/${route}`

	return (
		<div
			className={`list ${pathname === `/${route}` && "active-list"}`}
			onClick={() => history.push(`/${route}`)}
		>
			<div className="side-icon">
				<Icon color={isActiveMenu ? "#0d3dde" : "#010B1A"}/>
			</div>
		
			<p className={pathname === `/${route}` ? "active-text" : "menu-text"}>{menu}</p>
		</div>
	)
}
