import React from 'react'

// import { Icon } from '@iconify/react';

// import { ReactComponent as Bell } from "../../assets/icons/bell.svg";
// import { ReactComponent as CheckMarkCircle } from "../../assets/icons/checkmark-circle.svg";
// import { ReactComponent as FileImage } from "../../assets/icons/file-image.svg";
// import { ReactComponent as Money } from "../../assets/icons/money.svg";
// import { ReactComponent as Pallete } from "../../assets/icons/palette.svg";
// import { ReactComponent as Search } from "../../assets/icons/search.svg";
// import { ReactComponent as UserCheck } from "../../assets/icons/user-check.svg";
// import { ReactComponent as UserPlus } from "../../assets/icons/user-plus.svg";
// import { ReactComponent as UserRevoked } from "../../assets/icons/users.svg";
// import { ReactComponent as Users } from "../../assets/icons/user-x.svg";

interface Props {
    color: string
}

export const BackArrowIcon =({color}: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" viewBox="0 0 36.621 30" >
            <g id="Icon_feather-arrow-left" data-name="Icon feather-arrow-left" transform="translate(-6 -6)">
                <path id="Path_39" data-name="Path 39" d="M28.5,19.5H7.5a1.5,1.5,0,0,1,0-3h21a1.5,1.5,0,0,1,0,3Z" fill={color}/>
                <path id="Path_40" data-name="Path 40" d="M18,30a1.5,1.5,0,0,1-1.061-.439l-10.5-10.5a1.5,1.5,0,0,1,0-2.121l10.5-10.5a1.5,1.5,0,0,1,2.121,2.121L9.621,18l9.439,9.439A1.5,1.5,0,0,1,18,30Z" fill={color}/>
            </g>
        </svg>
    )
}

export const CheckedIcon = ({color}: Props) => {
    return (
        <svg width="27" height="30" viewBox="0 0 36.621 30" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.96137 11.9351L7.07464 13.8255L13.1493 19.9002L26.6499 6.40047L24.7595 4.51098L13.1493 16.0492L8.96137 11.9351ZM25.2995 14.5003C25.2898 16.7514 24.5739 18.9428 23.2527 20.7655C21.9315 22.5881 20.0716 23.9502 17.9352 24.6597C15.7987 25.3692 13.4935 25.3903 11.3444 24.7201C9.19532 24.0499 7.31076 22.7222 5.95636 20.924C4.60196 19.1259 3.84602 16.948 3.7951 14.6974C3.74418 12.4468 4.40084 10.237 5.67253 8.37943C6.94422 6.52186 8.7668 5.11023 10.8834 4.3435C12.9999 3.57677 15.3038 3.49361 17.4701 4.10576L19.5627 2.01319C17.9634 1.32849 16.2393 0.983667 14.4997 1.00059C11.8297 1.00059 9.21969 1.79234 6.99967 3.2757C4.77966 4.75907 3.04937 6.86743 2.02761 9.33418C1.00585 11.8009 0.73851 14.5153 1.2594 17.134C1.78029 19.7526 3.06601 22.1581 4.95398 24.046C6.84194 25.934 9.24736 27.2197 11.866 27.7406C14.4847 28.2615 17.1991 27.9942 19.6658 26.9724C22.1326 25.9506 24.2409 24.2203 25.7243 22.0003C27.2077 19.7803 27.9994 17.1703 27.9994 14.5003H25.2995Z" fill={color} stroke={color}/>
        </svg>

    )
} 

export const UserCheckedIcon = ({ color }: Props) => {
    return (
        <svg width="27" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.621 30">
        <g id="Icon_feather-user-check" data-name="Icon feather-user-check" transform="translate(0 -3)">
          <path id="Path_5" data-name="Path 5" d="M24,31.5v-3a6,6,0,0,0-6-6H7.5a6,6,0,0,0-6,6v3" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
          <path id="Path_6" data-name="Path 6" d="M18.75,10.5a6,6,0,1,1-6-6A6,6,0,0,1,18.75,10.5Z" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
          <path id="Path_7" data-name="Path 7" d="M25.5,16.5l3,3,6-6" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
        </g>
      </svg>
      )
}

export const UserRevokedIcon = ({ color }: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" viewBox="0 0 36.621 30">
            <g id="Icon_feather-user-x" data-name="Icon feather-user-x" transform="translate(0 -3)">
            <path id="Path_8" data-name="Path 8" d="M24,31.5v-3a6,6,0,0,0-6-6H7.5a6,6,0,0,0-6,6v3" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            <path id="Path_9" data-name="Path 9" d="M18.75,10.5a6,6,0,1,1-6-6A6,6,0,0,1,18.75,10.5Z" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            <path id="Path_10" data-name="Path 10" d="M27,12l7.5,7.5" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            <path id="Path_11" data-name="Path 11" d="M34.5,12,27,19.5" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            </g>
        </svg>
      
        )
}

export const UserPlusIcon = ({ color }: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" viewBox="0 0 36.621 30">
            <g id="Icon_feather-user-plus" data-name="Icon feather-user-plus" transform="translate(0 -3)">
            <path id="Path_20" data-name="Path 20" d="M24,31.5v-3a6,6,0,0,0-6-6H7.5a6,6,0,0,0-6,6v3" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            <path id="Path_21" data-name="Path 21" d="M18.75,10.5a6,6,0,1,1-6-6A6,6,0,0,1,18.75,10.5Z" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            <path id="Path_22" data-name="Path 22" d="M30,12v9" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            <path id="Path_23" data-name="Path 23" d="M34.5,16.5h-9" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            </g>
        </svg>
        )
}

export const UserMinusIcon = ({color}: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" viewBox="0 0 36.621 30">
            <g id="Icon_feather-user-minus" data-name="Icon feather-user-minus" transform="translate(0 -3)">
                <path id="Path_34" data-name="Path 34" d="M24,33a1.5,1.5,0,0,1-1.5-1.5v-3A4.505,4.505,0,0,0,18,24H7.5A4.5,4.5,0,0,0,3,28.5v3a1.5,1.5,0,0,1-3,0v-3A7.5,7.5,0,0,1,7.5,21H18a7.509,7.509,0,0,1,7.5,7.5v3A1.5,1.5,0,0,1,24,33Z" fill={color}/>
                <path id="Path_35" data-name="Path 35" d="M12.75,3a7.5,7.5,0,1,1-7.5,7.5A7.508,7.508,0,0,1,12.75,3Zm0,12a4.5,4.5,0,1,0-4.5-4.5A4.505,4.505,0,0,0,12.75,15Z" fill={color}/>
                <path id="Path_36" data-name="Path 36" d="M34.5,18h-9a1.5,1.5,0,0,1,0-3h9a1.5,1.5,0,0,1,0,3Z" fill={color}/>
            </g>
        </svg>
    )
}

export const BellIcon = ({ color }: Props) => {
    return (
        <svg width="27" height="30" viewBox="0 0 36.621 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.2536 10.1014C22.2536 7.9528 21.4 5.89217 19.8807 4.37286C18.3614 2.85354 16.3008 2 14.1522 2C12.0035 2 9.94289 2.85354 8.42357 4.37286C6.90426 5.89217 6.05072 7.9528 6.05072 10.1014C6.05072 19.5531 2 22.2536 2 22.2536H26.3043C26.3043 22.2536 22.2536 19.5531 22.2536 10.1014Z" stroke={color} stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.4882 27.6545C16.2508 28.0638 15.9101 28.4035 15.5002 28.6396C15.0902 28.8757 14.6254 29 14.1523 29C13.6792 29 13.2144 28.8757 12.8045 28.6396C12.3945 28.4035 12.0538 28.0638 11.8164 27.6545" stroke={color} stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    )
}


export const DollarIcon = ({ color }: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" viewBox="0 0 36.621 30">
            <path id="Icon_material-attach-money" data-name="Icon material-attach-money" d="M17.7,16.35c-3.4-.885-4.5-1.8-4.5-3.225,0-1.635,1.515-2.775,4.05-2.775,2.67,0,3.66,1.275,3.75,3.15h3.315A5.979,5.979,0,0,0,19.5,7.785V4.5H15V7.74c-2.91.63-5.25,2.52-5.25,5.415,0,3.465,2.865,5.19,7.05,6.2,3.75.9,4.5,2.22,4.5,3.615,0,1.035-.735,2.685-4.05,2.685-3.09,0-4.305-1.38-4.47-3.15H9.48c.18,3.285,2.64,5.13,5.52,5.745V31.5h4.5V28.275c2.925-.555,5.25-2.25,5.25-5.325C24.75,18.69,21.1,17.235,17.7,16.35Z" transform="translate(-9.48 -4.5)" fill={color}/>
        </svg>
      
        )
}

export const UsersIcon = ({ color }: Props) => {
    return (
        <svg width="27" height="30" viewBox="0 0 36.621 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26 29V26C26 24.4087 25.3679 22.8826 24.2426 21.7574C23.1174 20.6321 21.5913 20 20 20H8C6.4087 20 4.88258 20.6321 3.75736 21.7574C2.63214 22.8826 2 24.4087 2 26V29" stroke={color} stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20 8C20 9.18669 19.6481 10.3467 18.9888 11.3334C18.3295 12.3201 17.3925 13.0892 16.2961 13.5433C15.1997 13.9974 13.9933 14.1162 12.8295 13.8847C11.6656 13.6532 10.5965 13.0818 9.75736 12.2426C8.91825 11.4035 8.3468 10.3344 8.11529 9.17054C7.88378 8.00666 8.0026 6.80026 8.45673 5.7039C8.91085 4.60754 9.67989 3.67047 10.6666 3.01118C11.6533 2.35189 12.8133 2 14 2C15.5913 2 17.1174 2.63214 18.2426 3.75736C19.3679 4.88258 20 6.4087 20 8Z" stroke={color} stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M35 29V26C34.9979 24.6714 34.5549 23.3812 33.7405 22.3315C32.9261 21.2818 31.7864 20.5321 30.5 20.2" stroke={color} stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M24.5 2.2C25.7884 2.53218 26.9298 3.28319 27.7448 4.33493C28.5598 5.38667 29.002 6.67946 29.002 8.01C29.002 9.34053 28.5598 10.6333 27.7448 11.6851C26.9298 12.7368 25.7884 13.4878 24.5 13.82" stroke={color} stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}


export const LogoutIcon = ({ color} : Props) => {
    return (
        <>        
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="27" height="30"
             viewBox="0 0 490.3 490.3" xmlSpace="preserve">
        <g>
            <g>
                <path d="M0,121.05v248.2c0,34.2,27.9,62.1,62.1,62.1h200.6c34.2,0,62.1-27.9,62.1-62.1v-40.2c0-6.8-5.5-12.3-12.3-12.3
                    s-12.3,5.5-12.3,12.3v40.2c0,20.7-16.9,37.6-37.6,37.6H62.1c-20.7,0-37.6-16.9-37.6-37.6v-248.2c0-20.7,16.9-37.6,37.6-37.6h200.6
                    c20.7,0,37.6,16.9,37.6,37.6v40.2c0,6.8,5.5,12.3,12.3,12.3s12.3-5.5,12.3-12.3v-40.2c0-34.2-27.9-62.1-62.1-62.1H62.1
                    C27.9,58.95,0,86.75,0,121.05z"/>
                <path d="M385.4,337.65c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6l83.9-83.9c4.8-4.8,4.8-12.5,0-17.3l-83.9-83.9
                    c-4.8-4.8-12.5-4.8-17.3,0s-4.8,12.5,0,17.3l63,63H218.6c-6.8,0-12.3,5.5-12.3,12.3c0,6.8,5.5,12.3,12.3,12.3h229.8l-63,63
                    C380.6,325.15,380.6,332.95,385.4,337.65z"/>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        </svg>

</>        

    )
}

export const CookieIcon = ({ color } : Props) => {
    return (
        <svg id="palette_black_36dp" xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
            <g id="Group_207" data-name="Group 207">
                <rect id="Rectangle_44" data-name="Rectangle 44" width="29" height="29" fill="none"/>
            </g>
            <g id="Group_209" data-name="Group 209" transform="translate(2.919 2.919)">
                <g id="Group_208" data-name="Group 208">
                <path id="Path_33" data-name="Path 33" d="M13.675,2a11.675,11.675,0,1,0,0,23.351,2.927,2.927,0,0,0,2.919-2.919,2.972,2.972,0,0,0-.744-1.956.577.577,0,0,1,.438-.963H18.36a7.007,7.007,0,0,0,7.005-7.005C25.351,6.714,20.111,2,13.675,2Zm4.67,15.324H16.273A2.772,2.772,0,0,0,13.5,20.1a2.871,2.871,0,0,0,.686,1.824.731.731,0,0,1-.525,1.241,9.486,9.486,0,1,1,.015-18.973c5.225,0,9.486,3.736,9.486,8.319A4.821,4.821,0,0,1,18.346,17.324Z" transform="translate(-2 -2)" fill={color}/>
                <circle id="Ellipse_17" data-name="Ellipse 17" cx="1.824" cy="1.824" r="1.824" transform="translate(16.419 9.121)" fill={color}/>
                <circle id="Ellipse_18" data-name="Ellipse 18" cx="1.824" cy="1.824" r="1.824" transform="translate(12.77 4.743)" fill={color}/>
                <circle id="Ellipse_19" data-name="Ellipse 19" cx="1.824" cy="1.824" r="1.824" transform="translate(3.284 9.121)" fill={color}/>
                <circle id="Ellipse_20" data-name="Ellipse 20" cx="1.824" cy="1.824" r="1.824" transform="translate(6.932 4.743)" fill={color}/>
                </g>
            </g>
            </svg>
    )
}