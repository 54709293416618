import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";

import { ERROR, IDLE, LOADING, SUCCESS } from "../../../constants";
import { fetchClient } from "utils/api";

interface ProfileProps {
  token: string;
  profile_status?: string;
}

const useProfile = ({ token, profile_status }: ProfileProps) => {
  const [status, setStatus] = useState(IDLE);
  const [profiles, setProfiles] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const columns = React.useMemo(
    () => [
      {
        Header: "Bitclout user",
        accessor: "user",
        Cell: ({ value }: any) => (
          <div className="user-cont">
            {value.img ? <img src={value.img} alt="User" /> : <div className={`img-layout ${value.status}`}></div>}
            <p>{value.username}</p>
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Date/time",
        accessor: "createdAt",
        Cell: ({ value }: any) => moment(value).utc().format("DD [/] MM [-] hh:mm"),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: any) => <span className={`status ${value}`}>{value}</span>,
      },
    ],
    []
  );

  interface FetchProps {
    pageIndex: number;
    pageSize?: number;
  }
  const fetchProfiles = async ({ pageIndex, pageSize = 10 }: FetchProps) => {
    setStatus(LOADING);
    const data: any = await fetchClient({
      path: `/profiles/?page=${pageIndex}&limit=${pageSize}${profile_status ? `&status=${profile_status}` : ""}`,
      token,
    });

    if (data.status === "error") {
      setStatus(ERROR);
      return toast.error(data.message);
    }
    setProfiles(data.data);
    setTotalPages(data.totalPages);
    setStatus(SUCCESS);
  };

  useEffect(() => {
    fetchProfiles({ pageIndex: 1 });
    //eslint-disable-next-line
  }, []);

  const getProfileRows = (profile: any) => {
    return {
      id: profile.id,
      user: { username: profile.username, img: profile.profile_picture, status: profile.status.name.toLowerCase() },
      name: profile.business_name ? `${profile.business_name}` : `${profile.last_name} ${profile.first_name}`,
      email: profile.email,
      createdAt: profile.updated_at,
      status: profile.status.name.toLowerCase(),
    };
  };

  return {
    status,
    profiles,
    columns,
    totalPages,
    rows: profiles?.map(getProfileRows),
    fetchProfiles,
  };
};

export default useProfile;
