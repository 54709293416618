import { createContext, useCallback, useMemo, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import ProfileDetails from "./screens/admin/ProfileDetails";
import RejectedRequests from "screens/admin/RejectedRequests";
import CreateAdmin from "./screens/admin/CreateAdmin";
import AdminLayout from "./screens/admin/AdminLayout";
import ManageAdmins from "screens/admin/ManageAdmin";
import CreateAds from "./screens/admin/CreateAds";
import NewRequests from "screens/admin/NewRequests";

import { AdminRecoverPassword } from "screens/auth/RecoverPassword";
import { ResetAdminPassword } from "screens/auth/ResetPassword";
import { AdminLoginForm } from "screens/auth/Login";
import { AuthLayout } from "./screens/auth/Auth";

import VerifiedRequests from "screens/admin/VerifiedRequests";
import RevokedRequests from "screens/admin/RevokedRequests";
import PendingRequests from "screens/admin/PendingRequests";
import { _isAnEmpytyObject } from "utils";

import "react-toastify/dist/ReactToastify.css";
import CreateNFT from "screens/admin/CreateNFT";
import ScreenSizeWarningMessage from "components/ScreenSizeWarning";
import useResponsiveness from "screens/admin/hooks/useResponsiveness";

export const AuthContext = createContext({});

function App() {
  const history = useHistory();
  const [user, setUser] = useState<any>({});
  const [token, setToken] = useState("");
  const [isSignedIn, setIsSignedIn] = useState(false);

  const storeInSessionStorage = (user: any, token: string) => {
    sessionStorage.setItem("user", JSON.stringify(user));
    sessionStorage.setItem("token", token);
  };

  const setAuth = ({ data, tkn }: any) => {
    if (!_isAnEmpytyObject(data) && tkn) {
      setUser(data);
      setToken(tkn);
      setIsSignedIn(true);

      storeInSessionStorage(data, tkn);

      toast.success("Login successful");

      setTimeout(() => {
        history.push("/new-request");
      }, 2000);
    }
  };

  const handleLogout = useCallback(() => {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("token");
    setUser({});
    setToken("");
    setIsSignedIn(false);
    history.push("/");
    toast.success("User logged out successfully.");
  }, [history]);

  const memoizedValues = useMemo(
    () => ({
      user,
      token,
      isSignedIn,

      setUser,
      setToken,
      setIsSignedIn,
      handleLogout,
    }),
    [user, token, isSignedIn, setUser, setToken, setIsSignedIn, handleLogout]
  );

  const { isTabletOrMobile } = useResponsiveness();

  if (isTabletOrMobile) {
    return <ScreenSizeWarningMessage />;
  }
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        closeOnClick
        style={{ minWidth: "400px" }}
      />

      <AuthContext.Provider value={memoizedValues}>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <AuthLayout {...props}>
                <AdminLoginForm setAuth={setAuth} />
              </AuthLayout>
            )}
          />

          <Route
            exact
            path="/recover-password"
            render={(props) => (
              <AuthLayout {...props}>
                <AdminRecoverPassword />
              </AuthLayout>
            )}
          />

          <Route
            exact
            path="/reset-password/:token"
            render={(props) => (
              <AuthLayout {...props}>
                <ResetAdminPassword />
              </AuthLayout>
            )}
          />

          <Route
            path="/new-request"
            exact
            render={(props) => (
              <AdminLayout {...props}>
                <NewRequests />
              </AdminLayout>
            )}
          />

          <Route
            path="/new-request/:id"
            exact
            render={(props) => (
              <AdminLayout {...props}>
                <ProfileDetails token={token} />
              </AdminLayout>
            )}
          />

          <Route
            path="/verified-users"
            exact
            render={(props) => (
              <AdminLayout {...props}>
                <VerifiedRequests />
              </AdminLayout>
            )}
          />

          <Route
            exact
            path="/pending-verifications"
            render={(props) => (
              <AdminLayout {...props}>
                <PendingRequests />
              </AdminLayout>
            )}
          />

          <Route
            exact
            path="/revoked-verifications"
            render={(props) => (
              <AdminLayout {...props}>
                <RevokedRequests />
              </AdminLayout>
            )}
          />

          <Route
            exact
            path="/rejected-verifications"
            render={(props) => (
              <AdminLayout {...props}>
                <RejectedRequests />
              </AdminLayout>
            )}
          />

          <Route
            exact
            path="/create-admin"
            render={(props) => (
              <AdminLayout>
                <CreateAdmin token={token} />
              </AdminLayout>
            )}
          />

          <Route
            exact
            path="/manage-admins"
            render={(props) => (
              <AdminLayout>
                <ManageAdmins token={token} />
              </AdminLayout>
            )}
          />

          <Route
            exact
            path="/create-ads"
            render={(props) => (
              <AdminLayout>
                <CreateAds />
              </AdminLayout>
            )}
          />

          <Route
            exact
            path="/create-nft"
            render={(props) => (
              <AdminLayout>
                <CreateNFT />
              </AdminLayout>
            )}
          />
        </Switch>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
