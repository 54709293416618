/* eslint-disable no-useless-escape */
import cogoToast from "cogo-toast";
import { localStorage, sessionStorage } from "es-storage";


// REACT_APP_BASE_URL
// const proxyUrl = "https://limitless-mountain-31284.herokuapp.com/";

export const imageCompressionOptions = {
	maxSizeMB: 1,
	maxWidthOrHeight: 1000,
	useWebWorker: false,
};

export const generateActions = (action: string) => {
	action = action.toUpperCase();
	return {
		REQUEST: `${action}_REQUEST`,
		SUCCESS: `${action}_SUCCESS`,
		FAILURE: `${action}_FAILURE`,
	};
};

export const _validateEmail = (email: string) => {
	const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
};

export const _validateString = (text: string) =>
	text.replace(/([@#$%&=()<>*/\\])/g, "").trim();

export const _validateUrl = (urlString: string) => {
	// const urlRegex = /^((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/i;
	// const urlRegex = RegExp('(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$', 'i');
	// const urlRegex = urlString.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
	const urlRegex = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i
	//return urlRegex
	 return urlRegex.test(urlString)
}

export const _textCapitalize = (text: string) => {
	return text && text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const _isAnEmpytyObject = (obj: any) => {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
};

export const _getUser = () => {
	const local_user = sessionStorage.getObject("user");
	const user = _isAnEmpytyObject(local_user) ? null : local_user;
	return user;
};

export const callbackToast = (message: any) => {
	const { hide } = cogoToast.loading(message, {
		hideAfter: 0,
		position: "top-right",
	});

	return hide;
};

export const _getToken = () => {
	let token = "";
	const local_token = localStorage.getObject("token").access;
	token =
		(_isAnEmpytyObject(local_token) ? "" : local_token) ||
		sessionStorage.get("token");
	return token;
};

export const _isUserLoggedIn = () => {
	const user = _getUser();
	if (!_isAnEmpytyObject(user)) return true;
	return false;
};

export const showToast = (message: string, type?: string) => {
	if (type) type = type.toLowerCase();

	switch (type) {
		case "success":
			cogoToast.success(message, { position: "top-right" });
			break;
		case "info":
			cogoToast.info(message, { position: "top-right" });
			break;
		case "loading":
			cogoToast.loading(message, { position: "top-right" });
			break;
		case "warn":
			cogoToast.warn(message, { position: "top-right" });
			break;
		case "error":
			cogoToast.error(message, { position: "top-right" });
			break;

		default:
			cogoToast.info(message, { position: "top-right" });
			break;
	}
};



interface PasswordProps {
	password: string;
	password_confirmation: string
}

export const validatePasswords = ({password, password_confirmation}: PasswordProps) => {
	const isEqual = password === password_confirmation;
	const isMoreThan6Chars = password.length >= 6 && password.length < 32;
	
	// const containsUpperCase = /^[A-Z]*$/.test(password);

	if(!isEqual){
		return {validated: false, message: "Password does not match."}
	}
	if(!isMoreThan6Chars){
		return {validated: false, message: "Passwords must be between 6 to 32 characters."};
	}
	// if(!containsUpperCase){
	// 	return {validated: false, message: "Passwords must contain at least one uppercase."};
	// }

	return {validated: true}
}