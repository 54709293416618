import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";

import { Button } from "components"
import ProfileListing from "../../components/ProfileListing"
import Modal from './../../components/Modal';

import { BASE_URL, IDLE, LOADING, SUCCESS, ERROR } from "../../constants";
import { fetchClient } from '../../utils/api';
interface Props {
	token: string
}

interface FetchProps{
	pageIndex: number;
	pageSize?: number;
}

const ManageAdmins = ({ token }: Props) => {
	const [profiles, setProfiles] = useState<[]>([]);
	const [totalPages, setTotalPages] = useState(1);
	const [status, setStatus] = useState(IDLE);
	const [currPage, setCurrPage] = useState(1)


	const fetchAdmins = async ({pageIndex, pageSize = 10}: FetchProps) => {
		setStatus(LOADING);
		const data:any = await fetchClient({
			path: `/users?page=${pageIndex}&limit=${pageSize}`,
			token
		});
		if(data.status === "error"){
			toast.error(data.message);
			setStatus(ERROR)
			return;
		}


		setCurrPage(pageIndex)
		setProfiles(data.data);
		setTotalPages(data.totalPages);
		setStatus(SUCCESS);
	}

	useEffect(() => {
		fetchAdmins({ pageIndex: currPage});

		//eslint-disable-next-line
	}, [])


	const columns = React.useMemo(
		() => [
		  {
			Header: 'Name',
			accessor: 'name',
		  },
		  {
			Header: 'Email',
			accessor: 'email',
		  },
		  {
			Header: 'Date/time',
			accessor: 'createdAt',
			Cell: ({value}: any) => moment(value).utc().format('DD [/] MM [-] hh:mm')
		  },
		  {
			Header: 'Action',
			accessor: 'action',
			Cell: ({value}: any) => (
				<Modal
					onClick={handleDeleteAdmin}
					actionID={value.id}
					subtitle="You're about to delete an admin. Confirm if you want to proceed."
					status={status}
				>
						{({handleClick}: any) => (
							<Button 
								onClick={() => handleClick({modalType: "Delete Admin"})} 
								text={`${value.text} Admin`}
								className="delete-btn" 
							/>
						)}
				</Modal>

			)
		  },
		],
		//eslint-disable-next-line
		[]
	  )


	  interface AdminProps {
		createdAt: string;
		email: string;
		username: string;
		first_name: string;
		full_name: string;
		id: string;
		last_name: string;
		updated_at: string;
	  }
	  const getTableRows = (profile: AdminProps) => {
		  return {
			  id: profile.id,
			  name: profile.full_name,
			  email: profile.email,
			  created_at: profile.createdAt,
			  action: {text: "Delete", id: profile.id}
		  }
	  }

	const handleDeleteAdmin = async ({actionID, closeModal}: any) => {
			setStatus(LOADING);

			const response = await fetch(`${BASE_URL}/users/${actionID}`, {
				method: "DELETE",
				headers: {
					Authorization: "Bearer " + token
				}
			})
			if(response.status === 204){
				toast.success("Admin deleted successfully");
				setStatus(SUCCESS);
				fetchAdmins({pageIndex: currPage});
			}else{
				setStatus(ERROR);
				toast.success("Something went wrong.");				
			}
			closeModal();			
	}


	return (
		<div className="new-request">
			<ProfileListing 
				columns={columns}
				rows={profiles.map(getTableRows)}
				loading={status === LOADING}
				fetchData={fetchAdmins}
				totalPages={totalPages}	
			/>
		</div>
	)
}

export default ManageAdmins


// http://54.235.20.51/profiles/BC1YLgX7Zh92TD8p76rueoKR4m5gRqGk4zgvZQLi7LJgMCj7T683QGL/check
// http://54.235.20.51/profiles/BC1YLgX7Zh92TD8p76rueoKR4m5gRqGk4zgvZQLi7LJgMCj7T683QGL/check