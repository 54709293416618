import React, { ChangeEvent, useState } from "react";
import { Input, Button } from "components";
import Modal from "components/Modal";
import { toast } from "react-toastify";
import { IDLE, SUCCESS } from "../../constants";
import ImageUploading from "react-images-uploading";
import EditableField from "./components/EditableField";

interface Props {}
interface NFTProps {
  id: string;
  img: string;
  link: string;
  title: string;
}

const INIT_VALUES: NFTProps[] = [
  {
    id: "1",
    img: "",
    link: "asdasdasd",
    title: "asdasdasdas",
  },
  {
    id: "2",
    img: "",
    link: "asdasdasd",
    title: "asdasdasdas",
  },
  {
    id: "3",
    img: "",
    link: "asdasdasd",
    title: "asdasdasdas",
  },
  {
    id: "4",
    img: "",
    link: "asdasdasd",
    title: "asdasdasdas",
  },
];
const CreateNFT = (props: Props) => {
  const [status, setStatus] = useState(IDLE);
  const [nfts, setNfts] = useState(INIT_VALUES);
  const [inEditId, setInEditId] = useState<string | number>("");

  // console.log({nfts});

  //  const handleChange = (e: any) => {
  //     const {value, name} = e.target;
  //     setNfts((prev) => ({...prev, [name]: value}));
  //  }

  const handleNftCreation = ({ closeModal }: any) => {
    setStatus(SUCCESS);
    toast.success("NFT published successfully.");
    closeModal();
  };

  const handleSaveChanges = async ({ closeModal }: any) => {};
  return (
    <>
      <div className="create-nft">
        <h2>Create NFT</h2>
        <p className="instructions">
          252 x 396 PX is recommended for 1 and 2 while <br />
          252 x 186 PX is recommended for 3 and 4
        </p>

        <div className="nft-cont">
          {nfts?.map((item: NFTProps, index: number) => (
            <NFTItem nft={item} count={index + 1} setNfts={setNfts} inEditId={inEditId} setInEditId={setInEditId} />
          ))}
        </div>
        <div className="buttons-cont">
          <Modal onClick={handleNftCreation} subtitle="You're about to publish NFT images. Confirm if you want to publish it." status={status}>
            {({ handleClick }: any) => <Button type="submit" onClick={() => handleClick({ modalType: "Publish NFT" })} text="Publish NFT" className="verify" />}
          </Modal>

          <Modal subtitle="You made changes to the user profile data. Confirm if you want to save it." onClick={handleSaveChanges} status={status}>
            {({ handleClick }: any) => <Button onClick={() => handleClick({ modalType: "save changes" })} text="Save Changes" className="save" />}
          </Modal>
        </div>
      </div>
    </>
  );
};

interface NFTItemProps {
  nft: NFTProps;
  count: string | number;
  setNfts: React.SetStateAction<any>;
  inEditId: string | number;
  setInEditId: React.Dispatch<React.SetStateAction<string | number>>;
}
const NFTItem = ({ nft, count, setNfts, inEditId, setInEditId }: NFTItemProps) => {
  const { id, link, img, title } = nft;

  // Handle Image Upload
  const maxNumber = 1;

  const [images, setImages] = React.useState([]);

  const handleImageChange = (imageList: any) => {
    setImages(imageList);
    setNfts((prev: NFTProps[]) => {
      return prev.map((nft: NFTProps) => (nft.id === id ? { ...nft, img: imageList[0]["data_url"] } : nft));
    });
  };

  const handleChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;

    setNfts((prev: NFTProps[]) => {
      return prev.map((nft: NFTProps) => (nft.id === id ? { ...nft, [name]: value } : nft));
    });
  };

  return (
    <div className="nft-item">
      <p className="count">{count}</p>
      <div className="img-cont" style={{ backgroundImage: `url(${img})` }}>
        <ImageUploading value={images} onChange={handleImageChange} maxNumber={maxNumber} dataURLKey="data_url">
          {({ onImageUpload, dragProps }) => (
            <button
              {...dragProps}
              onClick={() => {
                setImages([]);
                onImageUpload();
              }}
              className="upload__image-wrapper"
            >
              Replace Image
            </button>
          )}
        </ImageUploading>
      </div>
      <form>
        <div className="form-row">
          <EditableField id={id} value={title} name="title" label="NFT Title" inEditId={inEditId} setInEditId={setInEditId} handleChange={handleChange} />
        </div>

        <div className="form-row">
          <Input type="text" label="NFT Link" placeholder="" name="link" value={link} onChange={(e: any) => handleChange(e)} required />
        </div>
      </form>
    </div>
  );
};
export default CreateNFT;
