import { toast } from "react-toastify";
import { fetchClient } from "utils/api";
import { LOADING, SUCCESS, ERROR } from "../../../constants";

const useProfileActions = ({ token, id, profile, editedFields, setEditedFields, setProfile, setStatus }: any) => {
  const generalUserAction = async ({ type, successMessage, callback }: any) => {
    setStatus(LOADING);
    const data: any = await fetchClient({
      method: "PATCH",
      path: `/profiles/${id}/${type}`,
      token,
    });

    if (data.status === "error") {
      setStatus(ERROR);
      return toast.error(data.message);
    }
    setStatus(SUCCESS);
    toast.success(successMessage);
    setProfile(data);
    callback();
  };

  const handleRevokeUser = ({ closeModal }: any) => {
    generalUserAction({
      type: "revoke",
      successMessage: "User verification revoked successfully",
      callback: closeModal,
    });
  };

  const handleVerifyUser = ({ closeModal }: any) => {
    generalUserAction({
      type: "verify",
      successMessage: "User verified successfully",
      callback: closeModal,
    });
  };

  const handleReVerifyUser = ({ closeModal }: any) => {
    generalUserAction({
      type: "verify",
      successMessage: "User re-verified successfully",
      callback: closeModal,
    });
  };

  const handleRejectUser = ({ closeModal }: any) => {
    generalUserAction({
      type: "reject",
      successMessage: "User rejected successfully",
      callback: closeModal,
    });
  };

  const handleSaveChanges = async ({ closeModal }: any) => {
    setStatus(LOADING);

    let updatedDetails: any = {};

    // Extract updated fields' values
    for (let i = 0; i < editedFields.length; i++) {
      const currField = editedFields[i];
      if (currField === "social_networks") {
        const social_networks = profile.social_networks.map(({ link, slug, socialNetwork }: { link: string; slug: string; socialNetwork: { slug: string } }) => ({
          link,
          slug: slug || socialNetwork.slug,
        }));
        updatedDetails[currField] = social_networks;
      } else {
        updatedDetails[currField] = profile[currField];
      }
    }

    // Make API call
    const data = await fetchClient({
      method: "PATCH",
      token,
      data: updatedDetails,
      path: `/profiles/${id}`,
    });
    if (data.status === "error") {
      setStatus(ERROR);
      return toast.error(data.message);
    }

    setProfile(data);
    setEditedFields([]);
    setStatus(SUCCESS);
    toast.success("Changes saved successfully.");
    closeModal();
  };

  return {
    handleRevokeUser,
    handleVerifyUser,
    handleReVerifyUser,
    handleRejectUser,
    handleSaveChanges,
  };
};

export default useProfileActions;
