import React, { useState } from "react";
import Modal from "react-modal";

import { Button } from "components";
import { LOADING } from "../constants";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0,0,0, 0.7)",
    // backgroundColor: "rgba(83, 79, 79, .9)"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: 400,
    minHeight: 400,
    border: "none",
    borderRadius: 10,
    transform: "translate(-50%, -50%)",
  },
};

const profileStatus = ["pending", "verified", "revoked"];

interface ModalProps {
  subtitle: string;
  status: string;
  actionID?: string;
  children: any;
  onClick: ({ closeModal }: any) => void;
}

interface actionProps {
  modalType?: string;
}

const ModalDialog = ({ subtitle, status, actionID = "", onClick, children }: ModalProps) => {
  const [type, setType] = useState<string>("");

  const [isOpen, setIsOpen] = React.useState(false);

  const handleClick = ({ modalType }: actionProps) => {
    setIsOpen((prev) => !prev);

    modalType && setType(modalType);
  };

  const closeModal = () => setIsOpen(false);

  return (
    <div>
      {children({ handleClick })}
      <Modal isOpen={isOpen} ariaHideApp={false} style={customStyles} onRequestClose={() => handleClick({ modalType: type })} contentLabel="Example Modal">
        <div className="modal-cont">
          <div className="modal-header">
            <h2>Please Confirm Action</h2>
            <p>{subtitle}</p>
          </div>

          <div className="modal-actions">
            <Button className="delete-btn" onClick={handleClick}>
              Cancel
            </Button>

            <Button onClick={() => onClick({ closeModal, actionID })} loading={status === LOADING} text={`${profileStatus.includes(type) ? `${type} user` : type}`} className="create-btn" />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalDialog;
