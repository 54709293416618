import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Button, Input } from "components";
import { toast } from "react-toastify";
import { BASE_URL, IDLE, LOADING } from "../../constants";
import { validatePasswords } from "utils";

const useQuery = () => new URLSearchParams(useLocation().search);

export const ResetAdminPassword = ({ nextStep, prevStep, form }: any) => {
  const history = useHistory();
  const query = useQuery();

  const { token } = useParams<{ token: string }>();

  const [status, setStatus] = useState(IDLE);
  const [passwords, setPasswords] = useState({
    password: "",
    password_confirmation: "",
  });

  const [error, setError] = useState<any>("");

  const handleChange = (e: any) => {
    const { name, value }: any = e.target;

    setPasswords((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setStatus(LOADING);
    const { validated, message }: any = validatePasswords(passwords);

    if (validated === false) {
      setError(message);
      setStatus(IDLE);
      setTimeout(() => {
        setError("");
      }, 1000);
      return;
    }

    try {
      const response: any = await fetch(`${BASE_URL}/password/reset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...passwords,
          token,
        }),
      });

      if (response.status === 204) {
        toast.success("Password reset successfully. Redirecting to Login.");
        setStatus(IDLE);
        setTimeout(() => {
          history.push("/");
        }, 3500);
      } else if (response.status === 403) {
        toast.error("Token Expired.");
        setStatus(IDLE);
      } else if (response.status === 500) {
        toast.error("Invalid Token.");
        setStatus(IDLE);
      } else {
        console.log(response);
        toast.error("Something went wrong.");
        setStatus(IDLE);
      }
    } catch (error: any) {
      toast.error(error.message);
      setStatus(IDLE);
    }
  };

  useEffect(() => {
    const tokenQuery = query.get("token");

    if (tokenQuery) {
      console.log(tokenQuery);
      // setToken(tokenQuery);
      // history.replace({
      // 	search: "",
      // })
    } else {
      // history.push("/")
    }
  }, [history, query]);
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <h3 id="title">Reset Password</h3>
      <Input
        type="password"
        name="password"
        value={passwords["password"]}
        placeholder="New Password"
        onChange={(e: any) => handleChange(e)}
        required
      />

      <Input
        type="password"
        name="password_confirmation"
        value={passwords["password_confirmation"]}
        placeholder="Confirm Password"
        onChange={(e: any) => handleChange(e)}
        required
      />
      <div className="form-errors">
        {error && <p id="orange-error">{error}</p>}
      </div>

      <div className="btn-row-box">
        <Button
          type="submit"
          text="Reset"
          className="small-blue-btn"
          loading={status === LOADING}
        />
      </div>
    </form>
  );
};
