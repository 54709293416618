
interface Props {
    fields: {
        img: string,
        cta: string,
        title: string,
        link: string,
        description: string,
        duration: string,
    }
}

export const Advert = ({fields}: Props) => {

    const {
        img = "",
        cta = "",
        title = "",
        link = "",
        description = "",
    } = fields;

    return (
        <div className="advert-section">
            <div className="advert-box">
                <div className="left">
                    <div className="img-box" >
                        <div className="img" style={{backgroundImage: `url(${img})`}}></div>
                    </div>
                    <div className="description">
                        <h5>{title}</h5>
                        <p>{description}</p>
                    </div>
                </div>
                <div className="btn-box">
                
                     <a 
                        style={{color: "fff", textDecoration: "none"}} 
                        href={link} 
                        target="_blank"
                        rel="noreferrer"
                    >
                        <button 
                            type="button"
                            className="join-btn" 
                        >
                            {cta}
                        </button>
                    </a>
                
                    {/* <Button
                        type="button"
                    >
                       
                    </Button> */}
                    
                </div>
            </div>
            {/* <p id="here"><a href="http://#">Advertise Here</a></p> */}
        </div> 
    )
}
