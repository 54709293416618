import { Table } from "components"

interface Props {
	rows: any
	columns: any
	loading: boolean
	onRowClick?: (id: string) => void
	fetchData?: ({ pageIndex }: {pageIndex: number}) => void;
	totalPages?: number
}


const ProfileListing = ({ rows, columns, fetchData, loading, totalPages, onRowClick}: Props) => {

	return (
		<div className="new-request">
			<div className="search-panel">
				<img src="/icons/search.svg" alt="icon" id="search-icon" />
				<input type="text" placeholder="Search User" />
			</div>
			<Table
				columns={columns}
				data={rows}
				onRowClick={onRowClick && onRowClick}
				className="request-table"
				fetchData={fetchData && fetchData}
				totalPages={totalPages || 1}
				loading={loading}
			/>
		</div>
	)
}

export default ProfileListing


// http://54.235.20.51/profiles/BC1YLgX7Zh92TD8p76rueoKR4m5gRqGk4zgvZQLi7LJgMCj7T683QGL/check
// http://54.235.20.51/profiles/BC1YLgX7Zh92TD8p76rueoKR4m5gRqGk4zgvZQLi7LJgMCj7T683QGL/check