import React from 'react'
import {Footer } from "components"
import { useLocation} from "react-router-dom"



export const AuthLayout = ({ children }: any) => {
    const {pathname} = useLocation()

    return (
        <div className={`signup `}>
            <section className={`section ${pathname === "/" && "welcome"}`}>
                <div className={` ${pathname === "/" ? "poster-box" : "post-cont"}`}>
                    <img src="/images/form-image.jpg" alt="poster" id="poster" />
                </div>
                <div className={`form-box ${pathname === "/" && "form-cont"}`}>
                    <div className="icon-box">
                        <img src="/icons/logo.svg" alt="icon" />
                    </div>
                    <>
                    {children}
                    </>
                </div>
            </section>
            <Footer />
        </div>
    )
}

