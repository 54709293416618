import { useContext} from 'react'
import { useHistory } from 'react-router';

import { AuthContext } from '../../App';
import ProfileListing from '../../components/ProfileListing';
import { FullPageLoader } from '../../components/Loader';

import { LOADING } from '../../constants';
import useProfile from './hooks/useProfile';

const VerifiedRequests = () => {

  const history = useHistory();
  const { token }: any = useContext(AuthContext);

  const { status, rows, columns, fetchProfiles, totalPages }= useProfile({token, profile_status: 'verified'});



  const handleClick = (id: string) => {
    history.push(`/new-request/${id}`);
  }

    
    if(status === LOADING && rows.length === 0){
        return (
        <div style={{width: '100%', height: '100%'}}>
          <FullPageLoader />
        </div>
        )
    }
    return (
        <div>
            <ProfileListing 
                columns={columns}
                rows={rows}
                onRowClick={handleClick}
                fetchData={fetchProfiles}
                loading={status === LOADING}
                totalPages={totalPages}
            />
        </div>
    )
}

export default VerifiedRequests
