import React, { useState, useMemo, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";

import { FullPageLoader } from "../../components/Loader";
import {
  CheckedIcon,
  BackArrowIcon,
  UserCheckedIcon,
  UserRevokedIcon,
} from "components/Icons/Icons";
import Modal from "../../components/Modal";
import { Button } from "components";

import { IDLE, BASE_URL } from "../../constants";
import { fetchClient } from "../../utils/api";
import { _isAnEmpytyObject } from "utils";
import ImagePopUp from "./components/ImagePopUp";
import { Loader } from "../../components/Loader";
import EditableField from "./components/EditableField";
import useProfileActions from "./hooks/useProfileActions";

interface Props {
  token: string;
}

const profileState: any = {
  pending: { color: "#ffc144", icon: CheckedIcon },
  revoked: { color: "#fe753e", icon: UserRevokedIcon },
  verified: { color: "#23baa6", icon: UserCheckedIcon },
  rejected: { color: "#010B1A", icon: CheckedIcon },
};

const ProfileDetails = ({ token }: Props) => {
  const history = useHistory();
  const [inEditId, setInEditId] = useState<string | number>("");
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(IDLE);
  const [profile, setProfile] = useState({});
  const [editedFields, setEditedFields] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false);
  const [uploadPhoto, setUploadPhoto] = useState<any>({
    data_url: "",
    file: {},
  });
  const params: any = useParams();
  const { id } = params;

  // Fetch User Data
  const fetchUserData = async () => {
    const data: any = await fetchClient({
      token,
      path: `/profiles/${id}`,
    });

    if (data.status === "error") {
      setLoading(false);
      return toast.error(data.message);
    }
    setProfile(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchUserData();

    return () => {
      setProfile({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Image Upload functionality
  const [images, setImages] = useState([]);
  const maxNumber = 69;

  const handleImageChange = (imageList: any) => {
    setImages(imageList);
    setUploadPhoto(imageList[0]);
  };

  const uploadImage = async () => {
    setUploading(true);
    try {
      const formData = new FormData();
      formData.append("profilePhoto", uploadPhoto.file);
      const response: any = await fetch(`${BASE_URL}/profiles/${id}/photos`, {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      });

      const data = await response.json();
      if (response.status === 200) {
        setUploading(false);
        return toast.success("Image replaced successfully.", {
          toastId: "imageToaster",
        });
      }
      if (response.status === 500) {
        setUploading(false);
        toast.error(response.statusText);
      }
      setUploading(false);
      return toast.error(data.message);
    } catch (error: any) {
      setUploading(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (uploadPhoto.data_url) {
      uploadImage();
    }
    //eslint-disable-next-line
  }, [uploadPhoto]);

  const modalMessages = useMemo(
    () => ({
      revoke: "Please confirm if you want to revoke user verification.",
      reject: "Please confirm if you want to reject user.",
      verify: "Please confirm if you want to verify user.",
      reverify: "Please confirm if you want to reverify user.",
      save_changes:
        "You made changes to the user profile data. Confirm if you want to save it.",
    }),
    []
  );

  // Actions on User's Profile
  const {
    handleRevokeUser,
    handleVerifyUser,
    handleReVerifyUser,
    handleRejectUser,
    handleSaveChanges,
  } = useProfileActions({
    id,
    token,
    profile,
    editedFields,
    setEditedFields,
    setProfile,
    setStatus,
  });

  if (loading && _isAnEmpytyObject(profile)) {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <FullPageLoader />
      </div>
    );
  }

  if (!loading && _isAnEmpytyObject(profile)) {
    return null;
  }

  const {
    email,
    first_name,
    headline,
    last_name,
    owner,
    photos,
    profile_picture,
    social_networks,
    status: userProfileStatus,
    username,
    business_name,
  }: any = profile;

  const statusInLowerCase = userProfileStatus.name.toLowerCase();
  const selfiePhoto = photos.find((photo: any) => photo.selfie);
  const profilePhoto = photos.find((photo: any) => !photo.selfie);
  const ProfileIcon = profileState[statusInLowerCase].icon;

  const handleSocialLinksChange = (e: React.ChangeEvent<any>, id: string) => {
    const { value } = e.target;
    const updatedSocialLinks = social_networks.map((social: any) => {
      if (social.id === id) {
        return { ...social, link: value };
      }
      return social;
    });
    setProfile((prev) => ({
      ...prev,
      social_networks: [...updatedSocialLinks],
    }));
  };

  const handleDetailsChange = (e: React.ChangeEvent<any>) => {
    const { value, name } = e.target;
    setProfile((prev) => ({ ...prev, [name]: value }));
  };

  const handleBlur = (
    e: React.ChangeEvent<any>,
    isSocialLink: boolean = false
  ) => {
    const { name }: { name: string } = e.target;

    const checkMate = isSocialLink ? "social_networks" : name;
    !editedFields.includes(checkMate) &&
      setEditedFields((prev: any) => [...prev, checkMate]);
  };
  return (
    <>
      <div className="verifications-container">
        <button onClick={history.goBack} className="back-btn">
          <BackArrowIcon color="#010B1A" />
          <span>Dashboard</span>
        </button>
        <div className="header">
          <h3>User Profile Data</h3>
          <div className="verification-status-cont">
            <div className="icon">
              <ProfileIcon color={profileState[statusInLowerCase].color} />
            </div>

            <div className={`${statusInLowerCase}-status`}>
              {statusInLowerCase === "pending" &&
                `${statusInLowerCase} Verification`}
              {statusInLowerCase === "revoked" &&
                `Verification ${statusInLowerCase}`}
              {statusInLowerCase === "verified" && `${statusInLowerCase}`}
              {statusInLowerCase === "rejected" && `${statusInLowerCase} User`}
            </div>
          </div>
        </div>

        <div className="profile-cont">
          <div className="left">
            {!owner && (
              <div className="image-cont">
                <h5>Selfie Photo</h5>
                <ImagePopUp img={selfiePhoto?.photo_url} />
              </div>
            )}
            <div className="image-cont">
              <h5>Profile Photo</h5>
              <ImagePopUp
                img={uploadPhoto?.data_url || profilePhoto?.photo_url}
              />
              <ImageUploading
                value={images}
                onChange={handleImageChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({ onImageUpload, dragProps }) => (
                  <>
                    {!uploading ? (
                      <div
                        {...dragProps}
                        onClick={onImageUpload}
                        className="upload__image-wrapper details"
                      >
                        <img src="/icons/dummy-img.svg" alt="icon" />
                        <span>Replace Image</span>
                      </div>
                    ) : (
                      <Loader primary />
                    )}
                  </>
                )}
              </ImageUploading>
            </div>
            <div className="image-cont last">
              <a
                href={`https://bitclout.com/u/${username}/`}
                target="_blank"
                rel="noreferrer"
                className="name"
              >
                <h5>Bitclout</h5>
                <img
                  src={profile_picture || "/icons/bitclout-photo.png"}
                  className="small-img"
                  alt=""
                />
                <p className="username">{username}</p>
              </a>
            </div>
          </div>
          <div className="right">
            <div className="text-cont">
              <h5>Name</h5>

              <div style={{ display: "flex", gap: "10px" }}>
                {owner ? (
                  <EditableField
                    small
                    id="business_name"
                    name="business_name"
                    value={business_name}
                    inEditId={inEditId}
                    setInEditId={setInEditId}
                    handleChange={handleDetailsChange}
                    handleBlur={handleBlur}
                  />
                ) : (
                  <>
                    <EditableField
                      small
                      id="last_name"
                      name="last_name"
                      value={last_name || owner.last_name}
                      inEditId={inEditId}
                      setInEditId={setInEditId}
                      handleChange={handleDetailsChange}
                      handleBlur={handleBlur}
                    />
                    <EditableField
                      small
                      id="first_name"
                      name="first_name"
                      value={first_name || owner.first_name}
                      inEditId={inEditId}
                      setInEditId={setInEditId}
                      handleChange={handleDetailsChange}
                      handleBlur={handleBlur}
                    />
                  </>
                )}
              </div>

              <h5 className="skills occupation">Occupation</h5>
              <EditableField
                small
                id="headline"
                name="headline"
                value={headline}
                className="skills"
                inEditId={inEditId}
                setInEditId={setInEditId}
                handleChange={handleDetailsChange}
                handleBlur={handleBlur}
              />
            </div>
            <div className="text-cont">
              <h5>Email</h5>
              <EditableField
                small
                id="email"
                name="email"
                value={email}
                inEditId={inEditId}
                setInEditId={setInEditId}
                handleChange={handleDetailsChange}
                handleBlur={handleBlur}
              />
            </div>
            <div className="text-cont">
              <h5>Account Type</h5>
              <p className="name">{owner ? "Business" : "Personal"}</p>
            </div>
            {owner && (
              <div className="text-cont">
                <h5>Owner</h5>
                <EditableField
                  small
                  id="owner"
                  name="owner"
                  value={`${owner.first_name} ${owner.last_name}`}
                  inEditId={inEditId}
                  setInEditId={setInEditId}
                  handleChange={handleDetailsChange}
                  handleBlur={handleBlur}
                />
              </div>
            )}
          </div>
        </div>

        <div className="social-cont">
          {social_networks?.map(({ id, link, slug }: any) => {
            return (
              <div className="social" key={id}>
                <h5 className="social-title">{slug} URL</h5>
                <EditableField
                  id={id}
                  name={slug}
                  value={link}
                  isSocialLink
                  inEditId={inEditId}
                  className="social-link"
                  setInEditId={setInEditId}
                  handleChange={handleSocialLinksChange}
                  handleBlur={handleBlur}
                />
              </div>
            );
          })}
        </div>

        <div className="buttons-cont">
          {statusInLowerCase === "pending" && (
            <>
              <Modal
                subtitle={modalMessages.verify}
                onClick={handleVerifyUser}
                status={status}
              >
                {({ handleClick }: any) => (
                  <Button
                    onClick={() => handleClick({ modalType: "verify" })}
                    text="Verify User"
                    className="verify"
                  />
                )}
              </Modal>
              <Modal
                subtitle={modalMessages.reject}
                onClick={handleRejectUser}
                status={status}
              >
                {({ handleClick }: any) => (
                  <Button
                    onClick={() => handleClick({ modalType: "reject" })}
                    text="Reject User"
                    className="reject"
                  />
                )}
              </Modal>
            </>
          )}
          {statusInLowerCase === "revoked" && (
            <Modal
              subtitle={modalMessages.reverify}
              onClick={handleReVerifyUser}
              status={status}
            >
              {({ handleClick }: any) => (
                <Button
                  onClick={() => handleClick({ modalType: "reverify" })}
                  text="Re-Verify User"
                  className="verify"
                />
              )}
            </Modal>
          )}

          {statusInLowerCase === "verified" && (
            <Modal
              subtitle={modalMessages.revoke}
              onClick={handleRevokeUser}
              status={status}
            >
              {({ handleClick }: any) => (
                <Button
                  onClick={() => handleClick({ modalType: "revoke" })}
                  text="Revoke Verification"
                  className="reject"
                />
              )}
            </Modal>
          )}

          <Modal
            subtitle={modalMessages.save_changes}
            onClick={handleSaveChanges}
            status={status}
          >
            {({ handleClick }: any) => (
              <Button
                onClick={() => handleClick({ modalType: "save changes" })}
                text="Save Changes"
                className="save"
              />
            )}
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ProfileDetails;
