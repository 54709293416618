export const BASE_URL = `https://${process.env.REACT_APP_BASEURL}`;
// export const BASE_URL = "https://54.235.20.51";

export const IDLE = "IDLE";

export const LOADING = "LOADING";

export const ERROR = "ERROR";

export const SUCCESS = "SUCCESS";
