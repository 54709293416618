import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { fetchClient } from "../utils/api";

import { CheckedIcon, UserCheckedIcon, UserRevokedIcon, UserMinusIcon } from '../components/Icons/Icons';

interface Props {
	token: any
}

interface CountProps {
	pending: number;
	revoked: number;
	verified: number;
	rejected: number;
}
export const Header = ({ token }: Props) => {

    const [profilesCount, setProfilesCount] = useState<CountProps>({
		pending: 0,
		revoked: 0,
		verified: 0,
		rejected: 0
	});

	const fetchProfiles = async () => {
		const data: any = await fetchClient({ path: "/profiles/totals", token });

		if(data.status === "error"){
			return toast.error(data.message);
		};

		setProfilesCount(data);
	}

	useEffect(() => {
		fetchProfiles();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	return (
	<div className="header">
			<div className="box">
				<div className="img-box" id="yellow">
					<CheckedIcon color="#fff"/>
				</div>
				<div className="details">
					<p id="title">Pending</p>
					<p id="count">{profilesCount.pending}</p>
				</div>
			</div>

			<div className="box">
				<div className="img-box" id="green">
					<UserCheckedIcon color="#fff"/>
				</div>
				<div className="details">
					<p id="title">Verified</p>
					<p id="count">{profilesCount.verified}</p>
				</div>
			</div>

			<div className="box">
				<div className="img-box" id="red">
					<UserRevokedIcon color="#fff"/>
				</div>
				<div className="details">
					<p id="title">Revoked</p>
					<p id="count">{profilesCount.revoked}</p>
				</div>
			</div>

			<div className="box">
				<div className="img-box" id="black">
					<UserMinusIcon color="#fff"/>
				</div>
				<div className="details">
					<p id="title">Rejected</p>
					<p id="count">{profilesCount.rejected}</p>
				</div>
			</div>
		</div>
	)
}
