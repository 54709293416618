import { useMediaQuery } from 'react-responsive'

function useResponsiveness() {
    
    // 1224px
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });

    return {
        isTabletOrMobile
    }
}

export default useResponsiveness


